<template>
  <div
    class="horizontal-layout horizontal-menu"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
    style="height:inherit"
  >

    <!-- NAVBAR -->
    <b-navbar
      :style="{
        backgroundColor: navbarType === 'static' && scrolledTo && skin === 'light' ? 'white' : null,
        boxShadow: navbarType === 'static' && scrolledTo ? 'rgba(0, 0, 0, 0.05) 0px 4px 20px 0px' : null,
      }"
      :toggleable="false"
      class="header-navbar navbar-shadow align-items-center navbar-brand-center navbar-fixed"
      :class="{'fixed-top': $store.getters['app/currentBreakPoint'] !== 'xl'}"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
      >
        <!-- <app-navbar-horizontal-layout-brand /> -->
        <!-- <app-navbar-horizontal-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" /> -->
      </slot>
    </b-navbar>
    <!--/ NAVBAR -->
    <div
      v-if="userData"
      class="horizontal-menu-wrapper"
    >
      <div
        v-if="!isNavMenuHidden"
        class="header-navbar navbar-expand-sm navbar navbar-horizontal navbar-light navbar-shadow menu-border px-1 d-xl-flex nav-menu-new"
        :class="[navbarMenuTypeClass]"
      >
        <div class="d-flex">
          <b-link
            href="/welcome"
            class="nav-link"
            @click="toggleVerticalMenuActive"
          >
            <b-img
              :src="appLogoImage"
              alt="logo"
              style="width: 88px;"
            />
          </b-link>
          <horizontal-nav-menu />
        </div>
        <b-navbar
            :style="{
            backgroundColor: navbarType === 'static' && scrolledTo && skin === 'light' ? 'white' : null,
            boxShadow: navbarType === 'static' && scrolledTo ? 'rgba(0, 0, 0, 0.05) 0px 4px 20px 0px' : null,
          }"
          class="header-navbar navbar-shadow align-items-center navbar-brand-center "
          style="min-height: 2rem;"
        >
          <b-navbar-nav  class="show-full-nav nav align-items-center ml-auto ez-col-right">
            <li class="mr-1">
              <b-form-input
                v-model="listBDC"
                :placeholder="$t('golf_common_scan_bdc_bagtag_locker')"
                style="width: 300"
                @change="quickViewDetail()"
              />
            </li>

            <courses-dropdown />
            <locale />
            <user-dropdown />
          </b-navbar-nav>
          <div class="more-full-nav">
          <ez-icon
            icon="ezGolf-icon-arrow-right"
            color="#ccc"
            size="18"
            @click="hideMoreNav = !hideMoreNav"
          />
          <div class="more-nav" :class="!hideMoreNav ? 'd-none' : ''">
            <li class="mr-1">
            <b-form-input
                v-model="listBDC"
                :placeholder="$t('golf_common_scan_bdc_bagtag_locker')"
                style="width: 300"
                @change="quickViewDetail()"
              />
            </li>

            <courses-dropdown />
            <locale />
            <user-dropdown />
          </div>
        </div>
        </b-navbar>
        
        
      </div>

      <!-- Vertical Nav Menu -->
      <vertical-nav-menu
        :is-vertical-menu-active="isVerticalMenuActive"
        :toggle-vertical-menu-active="toggleVerticalMenuActive"
        class="d-block d-xl-none"
      >
        <template #header="slotProps">
          <slot
            name="vertical-menu-header"
            v-bind="slotProps"
          />
        </template>
      </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->
    </div>

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- CONTENT -->
    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->
    <!--/ CONTENT -->

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>

    <slot name="customizer" />
  </div>
</template>

<script>
import vSelect from 'vue-select'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import AppNavbarHorizontalLayout from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayout.vue'
import AppNavbarHorizontalLayoutBrand from '@core/layouts/components/app-navbar/AppNavbarHorizontalLayoutBrand.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import { useScrollListener } from '@core/comp-functions/misc/event-listeners'

import { onUnmounted } from '@vue/composition-api'

// Content Renderer
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'

// Vertical Menu
/* eslint-disable import/order */
import VerticalNavMenu from '@core/layouts/layout-vertical/components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import mixinLayoutHorizontal from './mixinLayoutHorizontal'
/* eslint-enable import/order */

import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import SearchBar from '@/@core/layouts/components/app-navbar/components/SearchBar.vue'
import UserDropdown from '@/@core/layouts/components/app-navbar/components/UserDropdown.vue'
import CoursesDropdown from '@/@core/layouts/components/app-navbar/components/CoursesDropdown.vue'

import { $themeConfig } from '@themeConfig'
import useLayoutHorizontal from './useLayoutHorizontal'
import HorizontalNavMenu from './components/horizontal-nav-menu/HorizontalNavMenu.vue'
import { booking } from '@/api/booking'

export default {
  components: {
    vSelect,
    AppBreadcrumb,
    AppNavbarHorizontalLayout,
    AppNavbarHorizontalLayoutBrand,
    AppFooter,
    HorizontalNavMenu,
    Locale,
    SearchBar,
    UserDropdown,
    CoursesDropdown,

    BNavbar,

    // Content Renderer
    LayoutContentRendererDefault,
    LayoutContentRendererLeft,
    LayoutContentRendererLeftDetached,

    // Vertical Menu
    VerticalNavMenu,
  },
  mixins: [mixinLayoutHorizontal],
  data() {
    return {
      listBDC: null,
    }
  },
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app

    const {
      skin,
      navbarType,
      footerType,
      routerTransition,
      isNavMenuHidden,
    } = useAppConfig()

    // Vertical Menu
    const {
      isVerticalMenuActive, toggleVerticalMenuActive, overlayClasses, resizeHandler,
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    const {
      navbarMenuTypeClass,
      layoutClasses,
      footerTypeClass,
    } = useLayoutHorizontal(navbarType, footerType, isVerticalMenuActive)

    // Scroll Listener
    const { scrolledTo } = useScrollListener()

    return {
      appName,
      appLogoImage,
      // skin
      skin,

      // Layout
      layoutClasses,

      // Navbar
      navbarType,
      navbarMenuTypeClass,

      // Menu Hidden
      isNavMenuHidden,

      // Router Transition
      routerTransition,

      // Footer
      footerTypeClass,

      // Scroll Listeners
      scrolledTo,

      // Vertical Menu
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      overlayClasses,
      userData: JSON.parse(localStorage.getItem('userData')),
      hideMoreNav: false
      // listBDC: [],
    }
  },
  methods: {
    quickViewDetail() {
      if (this.listBDC) {
        const tmp = this.listBDC.substring(0, 1)
        if (tmp == 'D') {
          window.location.href = `/booking-driving-range/?bdc=${this.listBDC}`
        } else if (tmp == 'R') {
          window.location.href = `/booking-retail/?bdc=${this.listBDC}`
        } else if (tmp == '@' || tmp == '#') {
          this.getInfoBooking_RBK13(this.listBDC)
        } else {
          window.location.href = `/booking/?bdc=${this.listBDC}`
        }
        
      }
    },
    async getInfoBooking_RBK13(data) {
      const response = await booking.api_RBK13({KeyWord: data})
      if(response.Status === '200') {
        if(response.Data.Booking.length === 1) {
            if(response.Data.Booking[0].BookingType === 'COURSE') {
              window.location.href = `/booking/?bdc=${response.Data.Booking[0].BDC}`
            } else if (response.Data.Booking[0].BookingType === 'DRIVINGRANGE') {
              window.location.href = `/booking-driving-range/?bdc=${response.Data.Booking[0].BDC}`
            } else if (response.Data.Booking[0].BookingType === 'RETAIL') {
              window.location.href = `/booking-retail/?bdc=${response.Data.Booking[0].BDC}`
            }
        } else if (response.Data.Booking.length > 1) {
          response.Data.Booking.forEach(x => {
            if(x.BookingType === 'COURSE') {
              window.open(`/booking/?bdc=${x.BDC}`, '_blank')
            } else if (x.BookingType === 'DRIVINGRANGE') {
              window.open(`/booking-driving-range/?bdc=${x.BDC}`, '_blank')
            } else if (x.BookingType === 'RETAIL') {
              window.open(`/booking-retail/?bdc=${x.BDC}`, '_blank')
            }
          })
        } else if (response.Data.Booking.length == 0) {
          this.showToast('error', this.$t('golf_search_quick_no_booking'))
        }
      } else {
        this.showResToast(response)
      }
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
// .show-full-nav {
//   display: inline-block;
// }
.more-full-nav {
  display: none;
}
.nav-menu-new {
  justify-content: space-between;
}
@media only screen and (max-width: 1300px) {
  .show-full-nav {
    display: none;
  }
  .more-full-nav {
    display: block;
    .more-nav{
        position: absolute;
        border: 1px solid;
        background-color:#6699CC;
        list-style-type: none;
        border-radius: 5%;
        >li {
          margin: 10px 5px 0 5px;
        }
        right: 20px;
      }
  }
  .nav-menu-new  {
    justify-content: start;
  }
}
</style>
